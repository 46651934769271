html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  /* TEST */
  height: 100vh;
  width: 100vw;
  /* END TEST */
  overflow: hidden;
}

*,
*::before,
*::after {
  /* -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit; */
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */

  /* TEST */
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  /* END TEST */

  overflow: hidden;
  background-color: white;
}

#root {
  /* width: 100%;
  height: 100%; */

  /* TEST */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* END TEST */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
